import { h } from 'preact';
import Logo from '../../img/logo.jpg';
import { trackClick } from '../utils.js';

const navItems = [
 { link: '#', title: 'Home' },
 { link: '#about', title: 'About' },
 { link: '#services', title: 'Services' },
 { link: '#team', title: 'Team' },
 { link: '#location', title: 'Location' }
];

export const Header = () => {

  const handleClick = e => {
    e.preventDefault();
    document.body.classList.toggle('is-showingNav');
    const link = e?.currentTarget?.dataset?.link;
    if (link) {
      if (link === '#') {
        trackClick('nav', 'home');
        return window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      trackClick('nav', link.substr(1));
      document.querySelector(link).scrollIntoView({
        behavior: 'smooth'
      });
    } else {
      trackClick('nav', 'toggle');
    }
  }

  return (
    <header class="stripe stripe--navigation">
      <div class="content content--navigation">
        <h3 class="title"><a href="/"><img src={Logo} /></a></h3>

        <div class="navButtonWrapper">
          <a class="navButton" href="#" onClick={handleClick}>
            <span class="navButtonBar navButtonBar--top"></span>
            <span class="navButtonBar navButtonBar--middle"></span>
            <span class="navButtonBar navButtonBar--bottom"></span>
          </a>
        </div>
        <nav id="nav-main" class="nav-main" role="navigation">
          <ul class="navMain-list">
            {navItems.map(({ link, title }) => (
              <li class="navMain-listItem h25">
                <a class="navMain-listItem-link js-closeNav" href={link} onClick={handleClick} data-link={link}>{title}</a>
              </li>
            ))}
          </ul>
           <p class="u-textCenter u-block">
             <a
               class="btn btn--subNav u-textUppercase"
               href="#team"
               onClick={handleClick}
               data-link="#team"
               >Book Now</a>
           </p>
        </nav>
      </div>
    </header>
  );
}
