import { h, Fragment } from 'preact';
import { Header } from './Header';
import { Slider } from './Slider';
import { About } from './About';
import { Services } from './Services';
import { Location } from './Location';
import { Staff } from './Staff';

export const App = () => (
  <Fragment>
    <Header />
    <Slider />
    <About />
    <Services />
    <Staff />
    <Location />
  </Fragment>
);

export default App;
