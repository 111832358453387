import { h } from 'preact';

export const About = () => (
  <div class="stripe stripe--about">
    <div id="about" class="anchorLink"></div>
    <div class="content">
      <h3 class="u-padBottom2x">Valdo's was established in 2020 during the midst of the pandemic to give clients a place to indulge. The idea behind it is to give those who visit a place where they can feel relaxed, a family atmosphere, while taking care of a basic service.</h3>
      <h3 class="u-padBottom2x">We look forward to being your partners in making you always look and feel your best. Come visit us soon.</h3>
    </div>
  </div>
);
