import { h } from "preact";
import { useState } from "preact/hooks";
import clsx from "clsx";
import { trackClick } from "../utils.js";

const serviceList = [
  {
    name: "Buzzcut (all clipper no shear)",
    price: "$40",
    description: "Clipper cut only. No shear work or shave",
  },
  {
    name: "Haircut (shear work, wash, and style)",
    price: "$50",
    description:
      "Any haircut with shears and clippers. Comes with wash and style.",
  },
  {
    name: "Adult Haircut and Beard/Hot Shave",
    price: "$65",
    description:
      "Shear cut and fade with your choice of hot lather shave or beard lining.",
  },
  {
    name: "Adult Haircut with Hot Towel and Steam Shave",
    price: "$75",
    description:
      "Enjoy after your choice of haircut a relaxing hot towel shave with steam being applied to help make the shave smoother. The first hot towel to help open your pores and soften the hair follicles for a smooth shave. Then a second hot towel is applied to remove any stubs left behind for the closest shave possible. Then topped of with a soothing aftershave balm to help moisturize your skin.",
  },
  {
    name: "Presidential Treatment",
    price: "$100",
    description:
      "Treat yourself to the Total Package! It begins with a relaxing yet reinvigorating Facial clay mask to remove any impurities from your skin. Followed by a Head Shave or Full Haircut with 2 Hot Towels and a Steam Shave. This is the best package available!!!",
  },
  {
    name: "Luxury shave",
    price: "$50",
  },
  {
    name: "Steam Shave",
    price: "$40",
  },
  {
    name: "Graphics",
    price: "$70+",
    description: "Does not include Facial Hair",
  },
  {
    name: "Kids Haircut (5-12yrs)",
    price: "$40",
  },
];

const Service = ({ name, price, description, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const classes = clsx({
    ["servicesList--service"]: true,
    ["servicesList--serviceOpen"]: isOpen,
    ["servicesList--withDescription"]: description,
  });

  const handleClick = () => {
    if (!description) {
      return;
    }
    trackClick("services", "Presidential");
    setIsOpen(!isOpen);
  };

  return (
    <div class={classes} onClick={handleClick}>
      <div class="servicesList--item">
        {name}
        {description && <MoreButton />}
      </div>
      {isOpen && <div class="servicesList--description">{description}</div>}
      <div class="servicesList--price">{price}</div>
    </div>
  );
};

const MoreButton = () => <div class="arrow" />;

export const Services = () => {
  const handleClick = (e) => {
    e.preventDefault();
    trackClick("services", "team");
    document.querySelector("#team").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div class="stripe stripe--services">
      <div id="services" class="anchorLink"></div>
      <div class="content">
        <h3 class="u-textCenter u-textUppercase h25">Services</h3>
        <h3 class="u-padBottom2x">
          Our staff excels in all services including: hair cuts, steam shaves,
          kids cuts, fades, hair color, and beard trims.
        </h3>
        <div class="servicesList">
          {serviceList.map((props) => (
            <Service {...props} />
          ))}
          <p class="u-textCenter">
            <button class="bookNow" onClick={handleClick}>
              Book Now
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};
