import { h, Fragment } from 'preact';
const year = new Date().getFullYear();

export const Location = () => (
  <footer class="stripe stripe--location">
    <div id="location" class="anchorLink"></div>
    <div class="content content--map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.9278207549405!2d-87.78044318516001!3d41.872899379222574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e350a78e482af%3A0xc0ba7f300581dd92!2sValdo%E2%80%99s%20Barbershop!5e0!3m2!1sen!2sus!4v1633384964497!5m2!1sen!2sus" width="100%" height="400px" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <div class="location content content--location">
      <h3>Valdo's Barbershop</h3>
      <p>
        128 Harrison St <br />
        Oak Park, IL 60304<br />
        <a href="tel:+17086283025">(708) 628-3025</a>
      </p>
      <h3>Hours</h3>
      <dl class="hours">
        <dt>Sunday - Monday</dt>
        <dd>Closed</dd>
        <dt>Tuesday - Friday</dt>
        <dd>9am to 7pm</dd>
        <dt>Saturday</dt>
        <dd>8am to 5pm</dd>
      </dl>

      <p>
        <a href="https://www.facebook.com/Valdos-Barbershop-899556223557823/" target="_blank"><i class="fab fa-facebook fa-2x"></i></a>
        <a href="https://www.instagram.com/valdos_oakpark/" target="_blank"><i class="fab fa-instagram fa-2x"></i></a>
      </p>
      <p class="copyright">&copy; {year}</p>
    </div>
  </footer>
);
