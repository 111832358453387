import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import KeenSlider from 'keen-slider';

export const Slider = () => {

  useEffect(() => {
    const slider = new KeenSlider('.slider', {
      loop: true,
      defaultAnimation: {
        duration: 500
      }
    }, [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 5000)
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      }
    ]);
  }, []);

  return (
    <div class="stripe stripe--main keen-slider slider">
      <div class="keen-slider__slide slide slide1"></div>
      <div class="keen-slider__slide slide slide2"></div>
      <div class="keen-slider__slide slide slide3"></div>
      <div class="keen-slider__slide slide slide4"></div>
      <div class="keen-slider__slide slide slide5"></div>
    </div>
  );
}
