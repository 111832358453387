import { h } from "preact";
import { useEffect, useRef } from "preact/hooks";
import { trackClick } from "../utils.js";
import Valdo from "../../img/valdo.jpg";
import Javi from "../../img/javi.jpg";
import Sergio from "../../img/sergio.jpg";
import Mecca from "../../img/mecca.jpg";
import Will from "../../img/will.jpg";

const staffList = [
  { name: "Valdo", img: Valdo, booksyId: 89686 },
  { name: "Javi el barbero", img: Javi, booksyId: 7624 },
  { name: "Sergio E. Vasquez", img: Sergio, booksyId: 71533 },
  { name: "Mecca", img: Mecca, booksyId: 614830 },
  { name: "Will", img: Will, booksyId: 194731 },
];

const Staffer = ({ name, booksyId, img }) => {
  const staffer = useRef();
  let person;

  useEffect(() => {
    person = staffer.current;
    if (!person?.dataset?.booksyId) {
      return;
    }

    // const script = document.createElement('script');
    // script.src = `https://booksy.com/widget/code.js?id=${person.dataset.booksyId}&country=us&lang=en`;
    // person.appendChild(script);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    trackClick("staff", name);
    window.open(
      `https://booksy.com/widget-2021/?lang=en&country=us&id=${person.dataset.booksyId}`
    );
    // staffer.current.querySelector('.booksy-widget-button').click();
  };

  return (
    <div class="staffer" data-booksy-id={booksyId} ref={staffer}>
      <div class="staffer-thumbnail">
        <img src={img} onClick={handleClick} />
        <button class="bookNow bookNow--staffer" onClick={handleClick}>
          Book now
        </button>
      </div>
      <h3 class="staffer-name">{name}</h3>
    </div>
  );
};

export const Staff = () => (
  <div class="stripe stripe--staff">
    <div id="team" class="anchorLink"></div>
    <div class="content">
      <h3 class="u-padBottom2x">
        All the barbers are extremely talented individuals who strive off of
        constantly improving their craft. They'll welcome you their creative
        space where they'll have you leaving satisfied.
      </h3>
      <div class="stafferContainer">
        {staffList.map((staff) => (
          <Staffer {...staff} />
        ))}
      </div>
    </div>
  </div>
);
